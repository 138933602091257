import React from 'react';
import styled from 'styled-components';
import Button from './Button/Button';

export default function ServiceCardMobile(props) {
  const { color, title, description, buttonType } = props;

  return (
    <MainCardArea color={color}>
      <TextWrapper>
        <Title>{title}</Title>
        <TitleDescription>{description}</TitleDescription>
      </TextWrapper>

      <ButtonArea>
        <Button type={buttonType} />
      </ButtonArea>
    </MainCardArea>
  );
}

const MainCardArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  width: auto;
  max-width: 360px;
  height: 380px;
  background: ${({ color }) => color};
`;
const TextWrapper = styled.div`
  text-align: left; /* Align text to the left */
`;

const Title = styled.div`
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: medium;
  line-height: 36px;
  width: auto;
  padding: 24px 24px 12px 24px;
  color: ${({ theme }) => theme.color.secondary.white};
`;

const TitleDescription = styled.div`
  font-family: Arial;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -3%;
  font-weight: 200;
  color: ${({ theme }) => theme.color.secondary.white};
  text-align: left;
  padding: 12px 24px 12px 24px;
  width: 320px;
`;

const ButtonArea = styled.div`
  margin-top: auto; /* Push the button to the bottom of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
