import React from 'react';
import { useMediaQuery } from 'react-responsive';

import PanomixLogo from '../../../assets/logos/Panomix/Panomix.svg';
import { ReactComponent as CheveronSVG } from '../../../assets/icons/cheveron-down.svg';
import { ReactComponent as GlobeSVG } from '../../../assets/icons/globe-alt.svg';
import MenuButton from './MenuButton';
import Button from '../../Button/Button';
import { SIMPLE_MENU_OPTIONS, MENU_OPTIONS } from '../NAV_DATA';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import theme from '../../../styles/theme';

export default function NavDesktop(props) {
  const navigate = useNavigate();

  const moveToOtherPage = (url) => {
    navigate(url);
  };
  const { language, setLanguage } = props;

  return (
    <Wrapper>
      <MenuArea>
        <Logo
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.location.replace('/');
          }}
          src={PanomixLogo}
        />

        {SIMPLE_MENU_OPTIONS.map((menu, idx) => (
          <MenuWrapper
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              moveToOtherPage(menu.url);
            }}
          >
            <MenuName>{menu.title[language]}</MenuName>
            {/* <CheveronSVG /> */}
          </MenuWrapper>
        ))}
      </MenuArea>
      <ButtonArea>
        <LanguageButton>
          <TextButton
            onClick={() => setLanguage('en')}
            isActive={language === 'en'}
          >
            EN
          </TextButton>
          |
          <TextButton
            onClick={() => setLanguage('ko')}
            isActive={language === 'ko'}
          >
            KR
          </TextButton>
        </LanguageButton>
        <Button type='playbook' isInNav={true} />
        <Button type='requestDemo' isInNav={true} />
      </ButtonArea>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  height: 68px;
  background-color: ${({ theme }) => theme.color.secondary.white};
  z-index: 5;
  box-shadow: 0px 1px 3px rgba(9, 16, 55, 0.4);
  @media (min-width: 1440px) {
    padding-left: calc((100% - 1440px) / 2);
    padding-right: calc((100% - 1440px) / 2);
  }
`;

const Logo = styled.img`
  height: 22px;
  cursor: pointer;
  margin: 0px 40px 0px 40px;
`;

const MenuArea = styled.div`
  display: flex;
  gap: 14px;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const MenuName = styled.button`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375;
  color: black;
  cursor: pointer;
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  gap: 10px;
`;

const LanguageButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: black;
`;

const TextButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ isActive }) =>
    isActive ? 'black' : theme.color.neutral.spaceGray4};
  cursor: pointer;
`;
