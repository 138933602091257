import theme from '../../styles/theme';

export const PLAYBOOK_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Log In',
    ko: '로그인',
  },
  url: 'https://playbook.panomix.io',

  buttonColor: theme.color.neutral.spaceGray4,
  nameColor: theme.color.secondary.white,
};

export const LEARNMORE_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Learn More',
    ko: '더 알아보기',
  },
  url: 'https://panomix.notion.site/Playbook-123aaf87136e418ba7c80e694ffe32de',
  buttonColor: theme.color.others.yellow,
  nameColor: theme.color.secondary.black,
};

export const CONTACT_US_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Contact Us',
    ko: '상담하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.primary.green,
  nameColor: theme.color.secondary.black,
};

export const REQUEST_DEMO_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Request Demo',
    ko: '데모 요청하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const SCHEDULE_DEMO_BUTTON_OPTION = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Schedule Demo',
    ko: '데모 요청하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.primary.blue,
  nameColor: theme.color.secondary.white,
};

export const AI_CHAT_BUTTON_OPTION = {
  type: 'triggerBot',
  name: {
    en: 'Ask AI',
    ko: 'AI에게 문의하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.neutral.spaceGray2,
  nameColor: theme.color.secondary.white,
};

export const PLAYBOOK_LEARN_MORE = {
  type: 'moveToOtherPage',
  name: {
    en: 'Learn More',
    ko: '플레이북 알아보기',
  },
  url: '/playbook',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};

export const AIGEN_LEARN_MORE = {
  type: 'moveToOtherPage',
  name: {
    en: 'Learn More',
    ko: '생성형AI 알아보기',
  },
  url: '/ai',
  buttonColor: theme.color.neutral.spaceGray1,
  nameColor: theme.color.secondary.white,
};
export const CONSULTING_LEARN_MORE = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Contact Us',
    ko: '상담하기',
  },
  url: 'https://calendly.com/panomix/products',
  buttonColor: theme.color.primary.green,
  nameColor: theme.color.secondary.black,
};

export const PLAYBOOK_SIGN_IN = {
  type: 'moveToOtherWeb',
  name: {
    en: 'Sing In',
    ko: '로그인',
  },
  url: 'https://playbook.panomix.io',
  buttonColor: theme.color.neutral.spaceGray4,
  nameColor: theme.color.secondary.black,
};
