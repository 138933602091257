import React, { useState, useEffect } from 'react';
import PanomixLogo from '../../../assets/logos/Panomix/Panomix_logo.svg';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburgerIcon.svg';
import { ReactComponent as XIcon } from '../../../assets/icons/xIcon.svg';
import MenuButton from './MenuButton';

import { SIMPLE_MENU_OPTIONS } from '../NAV_DATA';
import styled from 'styled-components';

export default function NavMobile() {
  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const toggleScrolling = (type) => {
    let ele = document.querySelector('body');

    if (type === 'on') {
      ele.style.overflow = 'scroll';
      ele.style.height = 'auto';
      ele.style.touchAction = 'auto';
    } else {
      ele.style.overflow = 'hidden';
      ele.style.height = '100%';
      ele.style.touchAction = 'none';
    }
  };

  useEffect(() => {
    if (showMenu) {
      toggleScrolling('off');
    } else {
      toggleScrolling('on');
    }
  }, [showMenu]);

  return showMenu ? (
    <Wrapper showMenu={showMenu}>
      <TopArea>
        <Logo
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.location.replace('/');
          }}
          src={PanomixLogo}
        />
        <CloseButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            closeMenu();
          }}
        />
      </TopArea>
      <MenuArea showMenu={showMenu}>
        <MenuButtons>
          {SIMPLE_MENU_OPTIONS.map((menu, idx) => (
            <MenuButton key={idx} data={menu} closeMenu={closeMenu} />
          ))}
        </MenuButtons>
      </MenuArea>
    </Wrapper>
  ) : (
    <Wrapper showMenu={showMenu}>
      <TopArea>
        <Logo
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            window.location.replace('/');
          }}
          src={PanomixLogo}
        />
        <HamburgerButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowMenu(true);
          }}
          src={HamburgerIcon}
        />
      </TopArea>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100vw;
  height: ${({ showMenu }) => (showMenu ? '100%' : '56px')};
  background-color: ${({ theme }) => theme.color.secondary.black};

  transition: all ease 0.4s;

  z-index: 5;
`;

const TopArea = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 100vw;
`;

const MenuArea = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 78px;

  height: 100vh;
  transition: all ease 1s;
`;

const MenuButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 27px;
  color: white;
  transition: all ease 0.4s;
`;

const Logo = styled.img`
  height: 22px;
  cursor: pointer;
`;

const CloseButton = styled(XIcon)`
  width: 16px;
  height: 16px;
  fill: #fff;
  cursor: pointer;
`;

const HamburgerButton = styled(HamburgerIcon)`
  width: 18px;
  height: 12px;
  cursor: pointer;
`;
