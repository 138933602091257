import React from 'react';

import Button from '../../components/Button/Button';
import { ReactComponent as AboutUsSVG } from '../../assets/images/AboutUs.svg';

import styled from 'styled-components';

export default function AboutUsContent() {
  return (
    <>
      <Wrapper>
        <TopPage>
          <Title>We are a Mar-tech Company</Title>
          <TitleDescription>
            We provide leading marketing solutions that drive business growth.
          </TitleDescription>
          <Button type='contactUs' />
          <SVGWrapper>
            <AboutUsSVG />
          </SVGWrapper>
        </TopPage>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
`;

const TopPage = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 200px;
`;
const Title = styled.div`
  font-family: 'Poppins';
  font-size: 72px;
  font-weight: 400;
  line-height: 84px;
  width: ${(props) => (props.language === 'en' ? '980px' : '860px')};
  color: black;
  text-align: center;
  letter-spacing: -3px;
`;

const TitleDescription = styled.div`
  font-family: Arial;
  font-size: 20px;
  line-height: 26px;
  font-weight: 200;
  color: black;
  width: ${(props) => (props.language === 'en' ? '600px' : '480px')};
  text-align: center;
  margin-top: 20px;
`;

const SVGWrapper = styled.div`
  margin-top: 50px;
  margin-left: 48px;
`;
