import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export default function MenuButton({ data }) {
  const navigate = useNavigate();

  const moveToOtherPage = (url) => {
    navigate(url);
  };

  const moveToOtherWeb = (url) => {
    window.open(url, '_blank');
  };

  const triggerBot = () => {
    window.botpressWebChat.sendPayload({ type: 'trigger', payload: {} });
  };

  return (
    <MenuName
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        if (data.type === 'moveToOtherPage') {
          moveToOtherPage(data.url);
        } else if (data.type === 'moveToOtherWeb') {
          moveToOtherWeb(data.url);
        } else if (data.type === 'triggerBot') {
          triggerBot();
        }
      }}
    >
      {data.name}
    </MenuName>
  );
}

const MenuName = styled.button`
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.375;
  color: black;
  cursor: pointer;
`;
