export const MAIN_MENU_OPTIONS = [
  {
    type: 'moveToOtherPage',
    name: 'About',
    url: '/about',
  },
  {
    type: 'moveToOtherPage',
    name: 'Services',
    url: '/services',
  },
  {
    type: 'moveToOtherWeb',
    name: 'Playbook',
    url: 'https://playbook.panomix.io/',
  },
  {
    type: 'moveToOtherPage',
    name: 'Blog',
    url: '/blog',
  },
  {
    type: 'moveToOtherWeb',
    name: 'Careers',
    url: 'https://www.notion.so/panomix/Careers-68e5d519e9c547ad9cf5ae739b565a67',
  },
];

export const SIMPLE_MENU_OPTIONS = [
  {
    name: 'playbook',
    type: 'moveToOtherPage',
    title: {
      en: 'Playbook',
      ko: '플레이북',
    },
    url: '/playbook',
  },
  {
    name: 'genAI',
    type: 'moveToOtherPage',
    title: {
      en: 'GenAI',
      ko: '생성형AI',
    },
    url: '/ai',
  },
  {
    name: 'blog',
    type: 'moveToOtherPage',
    title: {
      en: 'Blog',
      ko: '블로그',
    },
    url: '/blog',
  },
  {
    name: 'company',
    type: 'moveToOtherPage',
    title: {
      en: 'Company',
      ko: '회사 소개',
    },
    url: '/about',
  },
];

export const MENU_OPTIONS = [
  {
    name: 'solutions',
    title: {
      en: 'Solutions',
      ko: '솔루션',
    },
    type: 'complexMenu',
    options: [
      {
        name: 'playbook',
        title: {
          en: 'Playbook',
          ko: '플레이북',
        },
        description: 'Advanced Media Mix Modeling for advanced growth teams',
        icon: '',
        url: '/playbook',
        type: 'page',
      },
      {
        name: 'application',
        title: {
          en: 'GenAI Application',
          ko: '생성형AI 애플리케이션',
        },
        description:
          'GenAI-based automation stacks for professional businesses with large data',
        icon: '',
        url: '/application',
        type: 'page',
      },
    ],
  },
  {
    name: 'consulting',
    title: {
      en: 'Consulting',
      ko: '컨설팅',
    },
    type: 'complexMenu',
    options: [
      {
        name: 'datalake',
        title: {
          en: 'Marketing Datalake',
          ko: '마케팅 데이터레이크',
        },
        description:
          'Cloud-based datalake build service for digital marketing data',
        icon: '',
        url: '/datalake',
        type: 'page',
      },
      {
        name: 'signal',
        title: {
          en: 'TikTok Signal Service',
          ko: '틱톡 시그널 서비스',
        },
        description: 'TikTok Pixel & eAPI service for businesses',
        icon: '',
        url: '/signal',
        type: 'page',
      },
    ],
  },
  {
    name: 'company',
    title: {
      en: 'Company',
      ko: '기업 소개',
    },
    type: 'simpleMenu',
    options: [
      {
        name: 'about',
        title: {
          en: 'About Us',
          ko: '기업 소개',
        },
        type: 'page',
        url: '/about',
      },
      {
        name: 'blogs',
        title: {
          en: 'Blogs',
          ko: '블로그',
        },
        type: 'page',
        url: '/blogs',
      },
      {
        name: 'careers',
        title: {
          en: 'Careers',
          ko: '채용',
        },
        type: 'web',
        url: 'https://www.notion.so/panomix/Careers-68e5d519e9c547ad9cf5ae739b565a67',
      },
    ],
  },
];
