import theme from '../../styles/theme';

export const MAIN_DATA = {
  en: {
    title: 'AI & ML automation stacks for data-driven businesses',
    subTitle:
      'From raw data processing to applications, our expertise lies in the details of every step in the data journey.',
    mainCards: [
      {
        title: 'Playbook',
        description:
          'Advanced MMM SaaS, solving problems of previous attribution models to help brands to measure true incrementality of all marketing channels.',
        buttonType: 'playbookLearnMore',
        color: theme.color.primary.blue,
      },
      {
        title: 'GenAI stack',
        description:
          'Combining multiple products & solutions to architect AI & ML automation solutions such as customer service chatbot and automated content management system.',
        buttonType: 'aigenLearnMore',
        color: theme.color.secondary.black,
      },
      {
        title: 'Consulting',
        description:
          'Delivering privacy-safe, secure, trustworthy and high-quality custom products that are impactful for growth and marketing.',
        buttonType: 'consultingLearnMore',
        color: theme.color.neutral.spaceGray3,
      },
    ],
  },
  ko: {
    title: '데이터 드리븐 비지니스를 위한 AI & ML 자동화',
    subTitle:
      '데이터 처리부터 애플리케이션까지 이어지는 데이터 여정의 모든 스텝들을 만들어갑니다.',
    mainCards: [
      {
        title: '플레이북',
        description:
          '마케팅 믹스 모델링 SaaS 플레이북은 이전의 어트리뷰션 모델의 문제를 해결하여 브랜드가 모든 마케팅 채널의 실제 증가 효과를 측정할 수 있도록 돕습니다.',
        buttonType: 'playbookLearnMore',
        color: theme.color.primary.blue,
      },
      {
        title: '생성형AI 스택',
        description:
          '여러 제품 및 솔루션을 결합하여 고객 서비스 챗봇 및 자동화된 콘텐츠 관리 시스템과 같은 LLM 기반 자동화 솔루션을 설계합니다.',
        buttonType: 'aigenLearnMore',
        color: theme.color.secondary.black,
      },
      {
        title: '컨설팅',
        description:
          '그로스와 마케팅을 위한 AI와 ML 기반의 맞춤 프로덕트를 안전하고 믿을수 있는 환경으로 구축할 수 있도록 서비스합니다.',
        buttonType: 'consultingLearnMore',
        color: theme.color.neutral.spaceGray3,
      },
    ],
  },
};
