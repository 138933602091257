import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavDesktop from '../../components/Nav/Desktop/NavDesktop';
import NavMobile from '../../components/Nav/Mobile/NavMobile';

import Footer from '../../components/Footer/Footer';
import styled from 'styled-components';
import DesktopContents from './Desktop/DesktopContents';
import MobileContents from './Mobile/MobileContents';
import { LanguageContext } from '../../contextApi/LanguageContext';
import { Helmet } from 'react-helmet';

function Main() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { language, setLanguage } = useContext(LanguageContext);

  const isDesktop = useMediaQuery({ minWidth: 981 });

  return (
    <>
      <Helmet>
        <title>Panomix | AI & ML for Growth</title>
        <meta
          name='description'
          content="Discover Panomix's AI & ML solutions, including Playbook — a marketing analytics SaaS with the advanced media mix modeling."
        />
      </Helmet>
      {isDesktop ? (
        <NavDesktop language={language} setLanguage={setLanguage} />
      ) : (
        <NavMobile language={language} setLanguage={setLanguage} />
      )}
      <Wrapper>
        {isDesktop ? (
          <DesktopContents language={language} />
        ) : (
          <MobileContents language={language} />
        )}
        <Footer />
      </Wrapper>
    </>
  );
}

export default Main;

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
`;
